import React from 'react'
import Layout from '../components/layout'
import ArticlesPageComponent from "../components/pages/articles";

const embedded = false;
const ArticlesPage = () => {
  return(
    <Layout embedded={embedded}>
      <ArticlesPageComponent embedded={embedded} />
    </Layout>
  )
}

export default ArticlesPage